export const DEFAULT_PAGE_INDEX = 1; 
export const DEFAULT_PER_PAGE = 20; 
export const DEFAULT_PER_PAGE_SUBSEQUENT = 20; 

export const PAGINATION = {
    currentPage: DEFAULT_PAGE_INDEX,
    perPage: DEFAULT_PER_PAGE,
    totalPages: 1,
    length: DEFAULT_PER_PAGE
};
