import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () =>
      import('./@main-shell/main-shell.module').then(m => m.MainShellModule),
  },
  {
    path: 'feed',
    loadChildren: () =>
      import('./@main-shell/main-shell.module').then(m => m.MainShellModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./pages/account/account.module').then(
        m => m.AccountModule
      ),
      canActivate: [AuthGuard] 
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('./pages/checkout/checkout.module').then(
        m => m.CheckoutModule
      ),
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('./pages/checkout/checkout.module').then(
        m => m.CheckoutModule
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then(
        m => m.AuthModule
      ),
  },
  {
    path: 'product/:titleSlug/:contentId',
    loadChildren: () =>
      import('./pages/details/details.module').then(
        m => m.DetailsModule
      ),
  },
  {
    path: 'vendor/:titleSlug/:contentId',
    loadChildren: () =>
      import('./pages/details/details.module').then(
        m => m.DetailsModule
      ),
  },
  {
    path: 'product/:contentId',
    loadChildren: () =>
      import('./pages/details/details.module').then(
        m => m.DetailsModule
      ),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
